/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Space,
  Spin,
  Typography,
} from 'antd';
import Search from 'antd/lib/input/Search';

import { PlusOutlined } from '@ant-design/icons';

// import ExcelToJSON from '../../Library/xlsx-helper.js';
import AppHelper from '../../Library/AppHelper';
import * as Consts from '../../Library/Consts';
import GenUtil from '../../Library/GenUtil';
import {
  BRQuote,
  ClientPrincipal,
  createBRQuoteObject,
  PriceListItemCollection,
} from '../../Library/Models';
import WebApiHelper from '../../Library/WebApiHelper';
import WrappedDrawer from '../Helpers/WrappedDrawer';
import { WrappedTable } from '../Helpers/WrappedTable';
import { QuoteDetail } from './QuoteDetail';
import { QuotePrint } from './QuotePrint';
import { QuoteSummary } from './QuoteSummary';

const { Title } = Typography;

export interface IQuotesListProps {
  curUser: ClientPrincipal;
  priceListItemsCol: PriceListItemCollection;
}

export const QuotesList: React.FunctionComponent<IQuotesListProps> = (props: React.PropsWithChildren<IQuotesListProps>) => {

  useEffect(() => {
    Consts.DEBUG_LOG_DIDMOUNT && console.log('[QuotesList] mounted!');
  }, []);


  const [loading, setLoading] = useState(false);

  const [showQuoteDetailDrawer, setShowQuoteDetailDrawer] = useState(false);
  const [showQuoteSummaryDrawer, setShowQuoteSummaryDrawer] = useState(false);
  const [showQuotePrintDrawer, setShowQuotePrintDrawer] = useState(false);

  const [curQuote, setCurQuote] = useState<BRQuote>(createBRQuoteObject());
  const [compareMode, setCompareMode] = useState<null | "M" | "Y">(null);
  const [allQuotes, setAllQuotes] = useState<BRQuote[]>([]);
  const [filQuotes, setFilQuotes] = useState<BRQuote[]>([]);


  useEffect(() => {
    // on page load, get all quotes
    let ignore = false;

    setLoading(true);

    setTimeout(() => {

      (async () => {

        // NOTE: do not use cache, we need live data always
        let getQuotesResp = await WebApiHelper.get1(`/api/web-svc?action=${'COSMOSDBQUERY'}&c=${Consts.CONTAINER_QUOTES}&q=${encodeURIComponent('SELECT * FROM c WHERE c.isDeleted=false ORDER BY c.createdDatetime DESC')}`);

        if (getQuotesResp.isErr) {
          AppHelper.showMessageErrorBar(getQuotesResp.msg);
        }
        else {
          let d = getQuotesResp.data;

          if (d.length > 0) {

            // #changeme fix new fields here, that are added to model after initial deployment
            //   they will be undefined when missing in the rehydrated object, set to the preferred default value
            //   later if the quote is saved the value will be set properly
            d.forEach((o: BRQuote) => {
              if (typeof o.status === 'undefined') o.status = Consts.ENUM_STATUS.DRAFT;
              if (typeof o.isAnnual === 'undefined') o.isAnnual = false;
              if (typeof o.showTermsFooter === 'undefined') o.showTermsFooter = false; // the default value for new quotes will be true, but for old records leave as false
              if (typeof o.closeDate === 'undefined') o.closeDate = null;
              if (typeof o.renewalDate === 'undefined') o.renewalDate = null;
            });

            if (!ignore) setAllQuotes(d);
            if (!ignore) setFilQuotes(d);
          }
        }

        if (!ignore) setLoading(false);

      })();
    }, 100);

    return () => { ignore = true; }
  }, []);


  function handleCreateQuote() {
    setCurQuote(createBRQuoteObject());
    setShowQuoteDetailDrawer(true);
  }


  function handleOpenQuoteSummary(o: BRQuote) {
    setCurQuote(o);
    setShowQuoteSummaryDrawer(true);
  }


  function handleCancelQuoteDetailDrawer() {
    // close panel
    setShowQuoteDetailDrawer(false);
  }


  function handleUpdateStatusFromSummary(o: BRQuote) {

    setAllQuotes((p) => {
      let idx = p.findIndex(x => x.id === o.id);
      if (idx < 0) return p;
      let t = [...p];
      t[idx] = o;
      return t;
    });

    setFilQuotes((p) => {
      let idx = p.findIndex(x => x.id === o.id);
      if (idx < 0) return p;
      let t = [...p];
      t[idx] = o;
      return t;
    });

    setCurQuote(o);
  }


  function handleSubmitClickInQuoteDetailDrawer(o: BRQuote, isNew: boolean) {
    // quote was created or updated
    setShowQuoteDetailDrawer(false);

    if (isNew) {
      // add quote to collection, then open it in summary view

      setAllQuotes((p) => {
        let t = [o, ...p]; // add new quote to beginning of collection
        return t;
      });

      setFilQuotes((p) => {
        let t = [o, ...p]; // add new quote to beginning of collection
        return t;
      });

      handleOpenQuoteSummary(o);

    }
    else {
      // update quote in collection

      setAllQuotes((p) => {
        let idx = p.findIndex(x => x.id === o.id);
        if (idx < 0) return p;
        let t = [...p];
        t[idx] = o;
        return t;
      });

      setFilQuotes((p) => {
        let idx = p.findIndex(x => x.id === o.id);
        if (idx < 0) return p;
        let t = [...p];
        t[idx] = o;
        return t;
      });

      handleOpenQuoteSummary(o);
    }
  }


  function handleCancelQuoteSummaryDrawer() {
    setShowQuoteSummaryDrawer(false);
  }


  function handleCancelQuotePrintDrawer() {
    setShowQuotePrintDrawer(false);
    setShowQuoteSummaryDrawer(true);
  }


  function handleDeleteSummary() {

    setAllQuotes((p) => {
      return p.filter(x => x.id !== curQuote.id);
    });

    setFilQuotes((p) => {
      return p.filter(x => x.id !== curQuote.id);
    });

    setShowQuoteSummaryDrawer(false);
  }


  function handleEditClickInQuoteSummaryDrawer() {

    setShowQuoteSummaryDrawer(false);
    setShowQuoteDetailDrawer(true);
  }


  function handleCloneClickInQuoteSummaryDrawer(o: BRQuote) {
    // add quote to top of collection
    // close summary drawer
    // open edit drawer for new quote

    setAllQuotes((p) => {
      let t = [o, ...p]; // add new quote to beginning of collection
      return t;
    });

    setFilQuotes((p) => {
      let t = [o, ...p]; // add new quote to beginning of collection
      return t;
    });

    setCurQuote(o);

    setShowQuoteSummaryDrawer(false);

    setShowQuoteDetailDrawer(true);
  }


  function handlePrintClickInQuoteSummaryDrawer() {
    setCompareMode(null);
    setShowQuoteSummaryDrawer(false);
    setShowQuotePrintDrawer(true)
  }


  function handlePrintMonthlyClickInQuoteSummaryDrawer() {
    setCompareMode("M");
    setShowQuoteSummaryDrawer(false);
    setShowQuotePrintDrawer(true)
  }

  function handlePrintYearlyClickInQuoteSummaryDrawer() {
    setCompareMode("Y");
    setShowQuoteSummaryDrawer(false);
    setShowQuotePrintDrawer(true)
  }

  const onSearch = (value: string) => {

    let val = value.trim().toLowerCase();

    if (val === '') {
      setFilQuotes([...allQuotes]);
      return;
    }

    let col = allQuotes.filter(x => {
      return (x.subject && x.subject.toLowerCase().indexOf(val) >= 0) ||
        (x.status && x.status.toLowerCase().indexOf(val) >= 0) ||
        (x.quoteNumber && x.quoteNumber.toLowerCase().indexOf(val) >= 0) ||
        (x.brContactName && x.brContactName.toLowerCase().indexOf(val) >= 0) ||
        (x.brClient && x.brClient.busName1 && x.brClient.busName1.toLowerCase().indexOf(val) >= 0) ||
        (x.brClient && x.brClient.contactName1 && x.brClient.contactName1.toLowerCase().indexOf(val) >= 0) ||
        (x.brClient && x.brClient.usState && x.brClient.usState.toLowerCase().indexOf(val) >= 0) ||
        (x.brClient && x.brClient.city && x.brClient.city.toLowerCase().indexOf(val) >= 0) ||
        x.salesItems.filter(y =>
          (y.description && y.description.toLowerCase().indexOf(val) >= 0) ||
          (y.productId && y.productId.toLowerCase().indexOf(val) >= 0)
        ).length > 0;
    });

    setFilQuotes(col);
  }


  const quotesCols: any[] = [
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: 35,
    //   render: (t: string, o: BRQuote) => (
    //     <Popconfirm
    //       title="Are you sure?"
    //       onConfirm={() => {
    //         handleDeleteSummary(o);
    //       }}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <DeleteOutlined className='grid-icon' />
    //     </Popconfirm>
    //   )
    // },
    {
      title: 'Quote Number',
      dataIndex: 'quoteNumber',
      key: 'quoteNumber',
      render: (t: string, o: BRQuote) => (
        <Button type='link' className='clr' disabled={loading} onClick={() => {
          handleOpenQuoteSummary(o);
        }}>{o.quoteNumber}</Button>
      ),
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.quoteNumber > b.quoteNumber ? 1 : -1;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (t: string, o: BRQuote) => (
        AppHelper.getStatusTag(o.status, null)
      ),
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.status > b.status ? 1 : -1;
      }
    },
    {
      title: 'Contact Name',
      dataIndex: 'brContactName',
      key: 'brContactName',
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.brContactName > b.brContactName ? 1 : -1;
      }
    },
    {
      title: 'Quote Date',
      dataIndex: 'quoteDate',
      key: 'quoteDate',
      render: (t: string, o: BRQuote) => (
        GenUtil.dateOnlyToString(o.quoteDate)
      ),
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.quoteDate > b.quoteDate ? 1 : -1;
      }
    },
    {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.modifiedBy > b.modifiedBy ? 1 : -1;
      }
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.subject > b.subject ? 1 : -1;
      }
    },
    {
      title: 'Biz Name',
      dataIndex: 'busName1',
      key: 'busName1',
      render: (t: string, o: BRQuote) => (
        o.brClient.busName1
      ),
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.brClient.busName1 > b.brClient.busName1 ? 1 : -1;
      }
    },
    {
      title: 'Biz Contact Name',
      dataIndex: 'contactName1',
      key: 'contactName1',
      render: (t: string, o: BRQuote) => (
        o.brClient.contactName1
      ),
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.brClient.contactName1 > b.brClient.contactName1 ? 1 : -1;
      }
    },
    {
      title: 'Total Sale',
      dataIndex: 'totalSale',
      key: 'totalSale',
      align: 'right',
      render: (t: string, o: BRQuote) => (
        GenUtil.numberToCurrency(o.totalSale, true)
      ),
      sorter: (a: BRQuote, b: BRQuote) => {
        return a.totalSale > b.totalSale ? 1 : -1;
      }
    }
  ]

  return (
    <>

      {
        loading && (
          <div className='wbs25 align-center'>
            <Spin tip="Loading..."></Spin>
          </div>
        )
      }

      {
        !loading && (
          <div className='wbs25'>

            <Title level={4} className='wtbs25'>Save, Print, and View Sales Quotes:</Title>

            <div className='wbs25'>
              <Space direction='horizontal'>

                <Search
                  placeholder="Search for Quote..."
                  onSearch={onSearch}
                  className={'w350'}
                  enterButton
                  allowClear={true}
                />

                <Button type='primary' onClick={handleCreateQuote} disabled={loading} icon={<PlusOutlined />}>Create Quote</Button>

              </Space>
            </div>

            <div className='wbs25'>
              <WrappedTable
                columns={quotesCols}
                dataSource={filQuotes}
                loading={loading}
                rowKey={'id'}
                skipRowSelection={true}
                useClientOnlyPager={true}
                size='small'
                pageSize={25}
              ></WrappedTable>
            </div>

          </div>
        )
      }


      <WrappedDrawer
        closable={true}
        handleCancelDrawer={handleCancelQuoteDetailDrawer}
        showDrawer={showQuoteDetailDrawer}
        title={curQuote.quoteNumber === '0000' ? 'Create New Quote' : `Edit Quote: ${curQuote.quoteNumber}`}
        width='80%'>

        <QuoteDetail
          curQuote={curQuote}
          curUser={props.curUser}
          priceListItemsCol={props.priceListItemsCol}
          quotes={allQuotes}
          handleCancelDrawer={handleCancelQuoteDetailDrawer}
          handleSubmitDrawer={handleSubmitClickInQuoteDetailDrawer}
        ></QuoteDetail>

      </WrappedDrawer>


      <WrappedDrawer
        closable={true}
        handleCancelDrawer={handleCancelQuoteSummaryDrawer}
        showDrawer={showQuoteSummaryDrawer}
        title={`Quote Summary: ${curQuote.quoteNumber}`}
        width='80%'>

        <QuoteSummary
          curQuote={curQuote}
          curUser={props.curUser}
          handleCancelDrawer={handleCancelQuoteSummaryDrawer}
          handleDeleteDrawer={handleDeleteSummary}
          handleEditDrawer={handleEditClickInQuoteSummaryDrawer}
          handlePrintDrawer={handlePrintClickInQuoteSummaryDrawer}
          handlePrintMonthlyDrawer={handlePrintMonthlyClickInQuoteSummaryDrawer}
          handlePrintYearlyDrawer={handlePrintYearlyClickInQuoteSummaryDrawer}
          handleCloneDrawer={handleCloneClickInQuoteSummaryDrawer}
          handleUpdateStatusDrawer={handleUpdateStatusFromSummary}
        ></QuoteSummary>

      </WrappedDrawer>


      <WrappedDrawer
        closable={true}
        handleCancelDrawer={handleCancelQuotePrintDrawer}
        showDrawer={showQuotePrintDrawer}
        title={`Print Quote: ${curQuote.quoteNumber}`}
        width='80%'>

        <QuotePrint
          priceListItemsCol={props.priceListItemsCol}
          compare={compareMode}
          curQuote={curQuote}
          curUser={props.curUser}
          handleCancelDrawer={handleCancelQuotePrintDrawer}
        ></QuotePrint>

      </WrappedDrawer>

    </>
  );
};