import React, { useState } from 'react';

import {
  Form,
  Input,
  Select,
  Typography,
} from 'antd';
import _ from 'lodash';

import GenUtil from '../../Library/GenUtil';
import {
  PriceListItem,
  PriceListItemCollection,
} from '../../Library/Models';

const { Title } = Typography;
const { Option } = Select;

const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export interface ITermCompareToolProps {
    priceListItemsCol: PriceListItemCollection;
}

export const TermCompareTool: React.FunctionComponent<ITermCompareToolProps> = (props: React.PropsWithChildren<ITermCompareToolProps>) => {

    const [form] = Form.useForm();
    const [selPriceLevel, setSelPriceLevel] = useState<string>('CORPORATE');
    const [selPriceSku, setSelPriceSku] = useState<string>('');
    const [selDiscount, setSelDiscount] = useState<number>(0);

    function handleOnFinish(formValues: any) {
    }

    function handlePriceLevelsChange(val: string) {
        setSelPriceLevel(val);
    }

    function renderPriceLevels() {
        let col1: string[] = [];
        props.priceListItemsCol.items.forEach((o) => {
            if (col1.findIndex((o2) => {
                return o2 === o.licAgrType;
            }) < 0) {
                col1.push(o.licAgrType);
            }
        });

        col1 = _.sortBy(col1);
        return col1.map((o, i) => {
            return (
                <Option key={i} value={`${o}`}>{`${o}`}</Option>
            );
        });
    }

    function handlePriceListItemsChange(val: string) {
        setSelPriceSku(val);
    }

    function renderPriceListItems() {
        const skuList = _.chain(props.priceListItemsCol.items)
            .filter(o => { return o.licAgrType === selPriceLevel && (o.source === "SW" || o.source === "NCE" || o.source==="EOS"); })
            .sortBy('description')
            .uniqBy(o => o.description)
            .map(o => o.description)
            .value();

        return skuList.map((o, i) => {
            return (
                <Option key={i} value={o}>{o}</Option>
            );
        });
    }

    function handleFormTextboxChange(e: any) {
        const d = Number(e.target.value);
        if (Number.isNaN(d) || d < 0 || d > 100) {
            setSelDiscount(0);

        } else {
            setSelDiscount(d);
        }
    }

    function getDiscount(price: number): number {
        const discount = selDiscount > 0 ?
            (selDiscount / 100) * price :
            0
        return price - discount;
    }

    return (
        <>
            <div className='wbs25'>
                <Form
                    form={form}
                    style={{ width: '75%' }}
                    {...formLayout}
                    layout="horizontal"
                    onFinish={handleOnFinish}
                >
                    <Title level={4} className='wtbs25'>Choose Sales Item to Compare:</Title>

                    <Form.Item
                        label="Price Level"
                        name="priceLevel"
                        initialValue="CORPORATE"
                    >
                        <Select
                            placeholder="Choose a price level"
                            showSearch
                            allowClear
                            filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={handlePriceLevelsChange}
                        >
                            {renderPriceLevels()}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Price List"
                        name="priceList"
                        initialValue={undefined}
                    >
                        <Select
                            placeholder="Choose a sales item"
                            showSearch
                            allowClear
                            filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={handlePriceListItemsChange}
                        >
                            {renderPriceListItems()}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Discount (%)"
                        name="quadiscountntity"
                        initialValue={0}
                        rules={[
                            {
                                message: 'Number must be between 0 and 100',
                                pattern: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/
                            }
                        ]}
                    >
                        <Input className='w200' onChange={handleFormTextboxChange} />
                    </Form.Item>
                </Form>
                {
                    selPriceSku &&
                    <div className='wbs25'>
                        <table className='termCompareTable'>
                            <tr>
                                <th>Description</th>
                                <th>Billed</th>
                                <th>Term</th>
                                <th>MSRP</th>
                                <th>B&R COST</th>
                                <th>Discount</th>
                            </tr>
                            {
                                props.priceListItemsCol.items
                                    .filter((o) => {
                                        return (
                                            (o.source === "SW" || o.source === "NCE" || o.source==="EOS") &&
                                            o.licAgrType === selPriceLevel &&
                                            o.description === selPriceSku)
                                    })
                                    .map((el: PriceListItem, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td>{el.description}</td>
                                                <td>{el.billingPlan}</td>
                                                <td>{el.termDuration}</td>
                                                <td className='numberCell'>{GenUtil.numberToCurrency(el.msrp, true)}</td>
                                                <td className='numberCell'>{GenUtil.numberToCurrency(el.brCost, true)}</td>
                                                <td className='numberCell'>{GenUtil.numberToCurrency(getDiscount(el.msrp), true)}</td>
                                            </tr>
                                        )
                                    })
                            }
                        </table>
                    </div>
                }
            </div>
        </>);
}