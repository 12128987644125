/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import {
  Button,
  Divider,
  Space,
} from 'antd';
import _ from 'lodash';

import { CloseOutlined } from '@ant-design/icons';

import * as Consts from '../../Library/Consts';
import {
  BRQuote,
  ClientPrincipal,
  PriceListItemCollection,
} from '../../Library/Models';
import PDFExport from './PDFExport';

export interface IQuotePrintProps {
  priceListItemsCol: PriceListItemCollection;
  compare: null | "M" | "Y";
  curUser: ClientPrincipal;
  curQuote: BRQuote;
  handleCancelDrawer: () => void;
}

export const QuotePrint: React.FunctionComponent<IQuotePrintProps> = (props: React.PropsWithChildren<IQuotePrintProps>) => {

  useEffect(() => {
    Consts.DEBUG_LOG_DIDMOUNT && console.log('[QuotePrint] mounted!');
  }, []);


  return (
    <>
      <div className='wbs25'>
        <div className='wbs25'>
          <PDFExport
            priceListItemCol={props.priceListItemsCol}
            compare={props.compare}
            curQuote={props.curQuote}
            curUser={props.curUser}>
          </PDFExport>
        </div>

        <Divider />

        <Space>
          <Button type='default' onClick={props.handleCancelDrawer} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </div>
    </>
  );
};
