/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Space,
    Typography,
} from 'antd';
import _ from 'lodash';

import {
    CheckOutlined,
    CloseOutlined,
    SaveOutlined,
} from '@ant-design/icons';

// import WebApiHelper from '../../Library/WebApiHelper';
import AppHelper from '../../Library/AppHelper';
import * as Consts from '../../Library/Consts';
import GenUtil from '../../Library/GenUtil';
import {
    ClientPrincipal,
    PriceListItemCollection,
    SalesItem,
} from '../../Library/Models';

const { Title } = Typography;
const { Option } = Select;


/* *********************************************************
THIS COMPONENT SHOULD NOT MAKE API CALLS
********************************************************* */

export interface ICustomItemDetailProps {
    curUser: ClientPrincipal;
    isAnnual: boolean;
    priceListItemsCol: PriceListItemCollection;
    curSalesItem: SalesItem;
    handleSubmitDrawer: (data: SalesItem, isNew: boolean) => void;
    handleCancelDrawer: () => void;
}

export const CustomItemDetail: React.FunctionComponent<ICustomItemDetailProps> = (props: React.PropsWithChildren<ICustomItemDetailProps>) => {

    useEffect(() => {
        Consts.DEBUG_LOG_DIDMOUNT && console.log('[CustomItemDetail] mounted!');
    }, []);


    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [totalSale, setTotalSale] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [profit, setProfit] = useState(0);
    const [margin, setMargin] = useState(0);
    const [discount, setDiscount] = useState(0);

    useEffect(() => {

        const si = props.curSalesItem;
        if (si.itemId !== '') {
            form.setFieldsValue({
                description: si.description,
                productId: si.productId,
                brCost: Number(si.brCost).toFixed(2),
                msrp: Number(si.msrp).toFixed(2),
                quantity: si.quantity,
                sellPrice: Number(si.sellPrice).toFixed(2),
                termDuration: si.termDuration,
                billingPlan: si.billingPlan,
                source: si.source
            });

            let { ts, tc, p, m } = calculateTotals(si.quantity, si.sellPrice, si.brCost * (props.isAnnual ? 12 : 1));

            setTotalSale(ts);
            setTotalCost(tc);
            setProfit(p);
            setMargin(m);
        }

    }, [props.curSalesItem, props.isAnnual, form]);


    function handleOnFinish(formValues: any) {
        if (loading) return;

        setLoading(true);
        setTimeout(() => {

            (async () => {
                let quantity = GenUtil.safeToNumber(formValues.quantity);
                let sellPrice = GenUtil.safeToNumber(formValues.sellPrice);
                let brCost = GenUtil.safeToNumber(formValues.brCost);
                let msrp = GenUtil.safeToNumber(formValues.msrp);

                // extra validation
                if (sellPrice < 0) {
                    AppHelper.showMessageWarningBar(`Sell Price cannot be less than zero.`);
                    setLoading(false);
                    return;
                }
                if (quantity <= 0) {
                    AppHelper.showMessageWarningBar(`Quantity must be greater than zero.`);
                    setLoading(false);
                    return;
                }
                if (sellPrice < brCost) {
                    AppHelper.showMessageWarningBar(`Sell Price cannot be less than B&R Cost.`);
                    setLoading(false);
                    return;
                }

                let { ts: totalSale, tc: totalCost, p: profit, m: margin } = calculateTotals(quantity, sellPrice, brCost * (props.isAnnual ? 12 : 1));
                let pl: SalesItem = { ...props.curSalesItem };

                pl.description = form.getFieldValue('description');
                pl.id = -1;
                pl.licAgrType = 'CUSTOM';
                pl.productId = form.getFieldValue('productId');
                pl.source = form.getFieldValue('source');

                pl.brCost = brCost;
                pl.msrp = msrp;
                pl.billingPlan = form.getFieldValue('billingPlan');
                pl.termDuration = form.getFieldValue('termDuration');

                pl.quantity = quantity;
                pl.sellPrice = sellPrice;

                pl.margin = margin;
                pl.profit = profit;
                pl.totalCost = totalCost;
                pl.totalSale = totalSale;


                if (pl.itemId === '') {
                    // create new sales item
                    pl.itemId = GenUtil.generateGuid();
                    props.handleSubmitDrawer(pl, true);
                }
                else {
                    // update existing sales item
                    props.handleSubmitDrawer(pl, false);
                }
            })();
        }, 100);
    }

    function handleFormTextboxChange() {
        let quantity = GenUtil.safeToNumber(form.getFieldValue('quantity'));
        let sellPrice = GenUtil.safeToNumber(form.getFieldValue('sellPrice'));
        let brCost = GenUtil.safeToNumber(form.getFieldValue('brCost'));

        let { ts, tc, p, m } = calculateTotals(quantity, sellPrice, brCost);

        setTotalSale(ts);
        setTotalCost(tc);
        setProfit(p);
        setMargin(m);
    }

    function calculateTotals(quantity: number, sellPrice: number, brCost: number) {
        if (quantity <= 0 || sellPrice <= 0) {
            return {
                ts: 0, tc: 0, p: 0, m: 0
            };
        }
        else {
            let _totalSale = quantity * sellPrice;
            let _totalCost = quantity * brCost;
            let _profit = _totalSale - _totalCost;
            let _margin = (_profit / _totalSale) * 100;
            return {
                ts: _totalSale, tc: _totalCost, p: _profit, m: _margin
            };
        }
    }

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    function handleFormDiscountChange(e: any) {
        const d = Number(e.target.value);
        if (Number.isNaN(d) || d < 0 || d > 100) {
            setDiscount(0);

        } else {
            setDiscount(d);
        }
    }

    function applyDiscount() {
        const msrp = GenUtil.safeToNumber(form.getFieldValue('msrp'));

        const discountValue = discount > 0 ?
            (discount / 100) * msrp :
            0

        form.setFieldsValue({
            sellPrice: (msrp - discountValue).toFixed(2)
        });

        handleFormTextboxChange();

        setDiscount(0);
        form.setFieldsValue({
            discountPercent: ''
        });
    }

    return (
        <>

            <div className='wbs25'>

                {
                    props.isAnnual && (
                        <div className='wbs25'>
                            <Alert message={`MSRP and B&R Cost has been adjusted to represent annual pricing.`} type="info" showIcon />
                        </div>
                    )
                }

                <Form
                    form={form}
                    style={{ width: '75%' }}
                    {...formLayout}
                    layout="horizontal"
                    onFinish={handleOnFinish}
                >
                    <Title level={4} className='wbs25'>Custom Item Info:</Title>

                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please enter a value' }]}
                            >
                                <Input className='w850' />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="Billed"
                                name="billingPlan"
                            >
                                <Input className='w200' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Term"
                                name="termDuration"
                            >
                                <Input className='w200' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Source"
                                name="source"
                                initialValue="Custom"
                            >
                                <Input className='w200' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="MSRP"
                                name="msrp"
                                rules={[{ required: true, message: 'Please enter a value' },
                                { message: 'Invalid US Currency', pattern: Consts.REGEX_CURRENCY }]}
                            >
                                <Input className='w200' />
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="B&amp;R Cost"
                                name="brCost"
                                rules={[{ required: true, message: 'Please enter a value' },
                                { message: 'Invalid US Currency', pattern: Consts.REGEX_CURRENCY }]}
                            >
                                <Input className='w200' onChange={handleFormTextboxChange} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="Product ID"
                                name="productId"
                            >
                                <Input className='w200' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Title level={4} className='wbs25'>Price &amp; Quantity:</Title>

                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="Quantity"
                                name="quantity"
                                initialValue={props.curSalesItem.itemId === '' ? undefined : props.curSalesItem.quantity}
                                rules={[{ required: true, message: 'Please enter a value' },
                                { message: 'Invalid number (integer)', pattern: /^(\d+)$/ }]}
                            >
                                <Input className='w200' onChange={handleFormTextboxChange} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="Sell Price"
                                name="sellPrice"
                                initialValue={props.curSalesItem.itemId === '' ? undefined : props.curSalesItem.sellPrice}
                                rules={[{ required: true, message: 'Please enter a value' },
                                { message: 'Invalid US Currency', pattern: Consts.REGEX_CURRENCY }]}
                            >
                                <Input className='w200' onChange={handleFormTextboxChange} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="Discount (%)"
                                name="discountPercent"
                                initialValue={''}
                                rules={[
                                    {
                                        message: 'Number must be between 0 and 100',
                                        pattern: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/
                                    }
                                ]}
                            >
                                <Input className='w150' onChange={handleFormDiscountChange} />
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Button type="primary" htmlType="button" onClick={applyDiscount} disabled={loading} icon={<CheckOutlined />}>Apply</Button>
                        </Col>

                    </Row>
                    <Row>

                        <Col span={8}>
                            <Form.Item
                                label="Total Sale" >
                                <Input value={GenUtil.numberToCurrency(totalSale, true)} readOnly disabled className='w200' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Profit" >
                                <Input value={GenUtil.numberToCurrency(profit, true)} readOnly disabled className='w200' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Form.Item label="Total Cost"                            >
                                <Input value={GenUtil.numberToCurrency(totalCost, true)} readOnly disabled className='w200' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Margin"                            >
                                <Input value={GenUtil.numberToCurrency(margin, false) + "%"} readOnly disabled className='w200' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <div className='wbs25' >
                        <Space>
                            <Button type="default" htmlType="button" onClick={props.handleCancelDrawer} disabled={loading} icon={<CloseOutlined />}>Cancel</Button>
                            <Button type="primary" htmlType="submit"
                                disabled={loading}
                                icon={<SaveOutlined />}
                                onClick={() => {
                                    form.submit();
                                }}>Save</Button>
                        </Space>
                    </div>

                </Form>
            </div >
        </>
    );
};